import React, { useState, useEffect } from "react";
import CarCard from "./CarCard";
import { getAllCars } from "./carAPI";
import { RingLoader } from "react-spinners";

function Vehicles() {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadCars = () => {
    getAllCars().then((data) => {
      if (data.error) {
        setLoading(true);
      } else {
        setCars(data.cars);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    loadCars();
  }, []);

  return (
    <div >
      {loading ? ( // Render the loader while loading is true
        <div className="flex items-center justify-center w-full h-screen">
          <RingLoader color="#36D7B7" loading={loading} size={100} />
        </div>
      ) : (
        <>
          <div className="max-w-2xl text-center py-16">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our Vehicle Listing
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Select a perfect one for your ride with your favorite one
            </p>
          </div>
          <div className="flex flex-wrap -mx-4">
            {cars &&
              cars.length > 0 &&
              cars.map((car, index) => (
                <div key={index} className="w-full sm:w-1 md:w-1/2 lg:w-1/3 px-4 mb-4">
                  <CarCard car={car} single={false} />
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Vehicles;
