import React from "react";
import airport from "../../images/services/airport.webp";
import family from "../../images/services/family.jpeg";
import travel from "../../images/services/travel.jpeg";
import wedding from "../../images/services/wedding.jpg";
import hire from "../../images/services/hire.jpeg";
import self from "../../images/services/car-br.jpeg";

function Services() {
  const services = [
    { img: airport, title: "Airport" },
    { img: family, title: "Family Outing" },
    { img: travel, title: "Travel" },
    { img: wedding, title: "Wedding" },
    { img: hire, title: "Hire" },
    { img: self, title: "Self Drive" }
  ];
  return (
    <div className="flex flex-wrap -mx-4">
      {services &&
        services.length > 0 &&
        services.map((service, index) => (
          <div key={index} className="w-full sm:w-1 md:w-1/2 lg:w-1/3 px-4 mb-4">
            <div className="relative group rounded-lg overflow-hidden">
              <img
                className="w-full h-40 object-contain object-cover" // Use both object-contain and object-cover classes
                src={service.img}
                alt="services"
              />
              <div className="absolute inset-0 bg-black opacity-50 transition-opacity group-hover:opacity-75 flex items-center justify-center text-white text-xl font-semibold">
                <p>{service.title}</p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default Services;
