import React from "react";
import "./style.css"; // You can add custom styles in style.css
// import CarImg from "../images/car-br.jpeg";
// import Layout from "./Layout";
import Vehicles from "../components/car/Vehicles"
import Services from "../components/Services/Services"
// import Reviews from "../components/Reviews/Reviews"
import ab1 from "../images/about/ab3.jpeg"
import ab2 from "../images/about/cover2.jpeg"
import ab3 from "../images/about/ab5.jpg"
import ab4 from "../images/about/ab4.jpeg"
import ab5 from "../images/about/ab1.jpg"
import ab6 from "../images/about/ab2.jpg"
import ab7 from "../images/about/cover3.jpeg"

function Home() {

  const iconStyle = {
    marginRight: "16px", // Add spacing between icons
    fontSize: "20px", // Increase the icon size
  };

  return (
    <div>
      <div className="relative overflow-hidden bg-white">
        <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
          <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
            <div className="sm:max-w-lg">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Best Car Rental Site - Choose yours
              </h1>
              <p className="mt-4 text-xl text-gray-500">
                Find A Perfect Car To Trip With Your Family, for a better & safety journey, We're here with you
              </p>
            </div>
            <div>
              <div className="mt-10">
                {/* Decorative image grid */}
                <div
                  aria-hidden="true"
                  className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                >
                  <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                          <img
                            src={ab1}
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src={ab2}
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                      </div>
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src={ab3}
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src={ab4}
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src={ab5}
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                      </div>
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src={ab6}
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src={ab7}
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <a
                    href="https://www.google.com/search?q=AJ+Car+rentals&client=safari&rls=en&sxsrf=AJOqlzUrySxfHwIdDHFfgyoQgKZES04W3A%3A1673544091497&ei=m0HAY-CCHsO83LUP9rSwgAg&ved=0ahUKEwigvNvIxcL8AhVDHrcAHXYaDIAQ4dUDCA4&uact=5&oq=AJ+Car+rentals&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzILCC4QgAQQxwEQrwEyBQgAEIAEMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjoKCAAQRxDWBBCwAzoECCMQJzoFCAAQkQI6BAgAEEM6CwgAEIAEELEDEIMBOhEILhCABBCxAxCDARDHARDRAzoECC4QQzoQCC4QsQMQgwEQxwEQ0QMQQzoKCAAQsQMQgwEQQzoKCC4QxwEQ0QMQQzoNCAAQsQMQgwEQyQMQQzoHCC4Q1AIQQzoKCAAQgAQQhwIQFDoICAAQgAQQsQM6BwgjEOoCECc6DQgAEI8BEOoCELQCGAE6BAgAEAM6CAguELEDEIMBOggIABCxAxCDAToHCC4QsQMQQzoLCC4QgwEQsQMQgAQ6CwguEIAEELEDEIMBOgUILhCABDoOCC4QgAQQxwEQrwEQ1AI6EAguEIAEEIcCEMcBEK8BEBQ6BwguEIAEEA06BwgAEIAEEA06CQguEIAEEA0QCjoICAAQCBAeEA06BQgAEIYDSgQIQRgASgQIRhgBUJELWPaaAWCZnAFoE3ABeACAAYUBiAHLHJIBBDIuMzCYAQCgAQGwARHIAQjAAQHaAQYIARABGAo&sclient=gws-wiz-serp"
                    className="inline-block rounded-md border border-transparent bg-red-500 px-4 py-2 text-center font-medium text-white hover:bg-red-700"
                    >
                    <i className="fas fa-briefcase" style={iconStyle}></i>
                    Business Profile
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
        <section className="vehicle-listing" id="vehicle-listing">
          <Vehicles/>
        </section>
        <section className="our-services" id="our-services">
          <Services/>
        </section>
        {/* <section className="our-services" id="our-services">
          <Reviews/>
        </section> */}
      </div>
  );
}

export default Home;
