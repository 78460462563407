import React from "react";
import "./style.css"; // You can add custom styles in style.css
import Img from "../images/about/cover1.jpeg";
import Layout from "./Layout";
import { CloudArrowUpIcon, LockClosedIcon, BriefcaseIcon, ServerIcon, UserGroupIcon, FingerPrintIcon, ClockIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'On Time',
    description:
      'Morbi viverra dui mi arcu sed. Tellus semper adipiscing suspendisse semper morbi. Odio urna massa nunc massa.',
    icon: ClockIcon,
  },
  {
    name: 'Uniqueness',
    description:
      'Sit quis amet rutrum tellus ullamcorper ultricies libero dolor eget. Sem sodales gravida quam turpis enim lacus amet.',
    icon: FingerPrintIcon,
  },
  {
    name: 'Friendliness',
    description:
      'Quisque est vel vulputate cursus. Risus proin diam nunc commodo. Lobortis auctor congue commodo diam neque.',
    icon: UserGroupIcon,
  },
  {
    name: '24 X 7 Support',
    description:
      'Arcu egestas dolor vel iaculis in ipsum mauris. Tincidunt mattis aliquet hac quis. Id hac maecenas ac donec pharetra eget.',
    icon: BriefcaseIcon,
  },
]

function About() {
  return (
      <Layout className="mb-48">
            <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
              <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                  className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                      width={200}
                      height={200}
                      x="50%"
                      y={-1}
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M100 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                  </defs>
                  <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                    <path
                      d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                      strokeWidth={0}
                    />
                  </svg>
                  <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
              </div>
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                  <div className="lg:pr-4">
                    <div className="lg:max-w-lg">
                      <p className="text-base font-semibold leading-7 text-red-600">Our Story</p>
                      <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">AJ Car Rentals</h1>
                      <p className="mt-6 text-xl leading-8 text-gray-700">
                        AJ Car rentals is a rental service provided by AJ. Head office of AJ Rentals situated in Viyaparimoolai, Point Pedro. We provide 24 * 7 service all over Sri Lanka, we are the part of Sri Lankan tourism. You can book us where ever from the world just by a call +94773912104.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                  <img
                    className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[35rem]"
                    src={Img}
                    alt=""
                  />
                </div>
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                  <div className="lg:pr-4">
                    <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                      <ul className="mt-8 space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CloudArrowUpIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                          <span>
                            <strong className="font-semibold text-gray-900">Push to deploy.</strong> Lorem ipsum, dolor sit amet
                            consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate
                            blanditiis ratione.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                          <span>
                            <strong className="font-semibold text-gray-900">SSL certificates.</strong> Anim aute id magna aliqua
                            ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <ServerIcon className="mt-1 h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                          <span>
                            <strong className="font-semibold text-gray-900">Database backups.</strong> Ac tincidunt sapien
                            vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white py-5 sm:py-5">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                  <h2 className="text-base font-semibold leading-7 text-red-600">WHY CHOOSE US</h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    We Have Everything you need, Call Us
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas a elementum
                    pulvinar et feugiat blandit at. In mi viverra elit nunc.
                  </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                  <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                    {features.map((feature) => (
                      <div key={feature.name} className="relative pl-16">
                        <dt className="text-base font-semibold leading-7 text-gray-900">
                          <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-600">
                            <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                          </div>
                          {feature.name}
                        </dt>
                        <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
      </Layout>
  );
}

export default About;
