import React, { useState, useEffect } from "react";
import { isAuthenticated } from "../auth/index";
import Cards from "./Cards";
import { getAllCars } from "../car/carAPI";
import { getAllForms } from "../manage-forms/api";
// import { getUsersCount } from "../user/api";
// import { getOrdersCount } from "../manage-orders/api";
// import BACKEND_URL from "../../Config";
import logoProf from "../../images/logo.png"

function Dashboard() {
  const { user, token } = isAuthenticated();
  const { name, email, address, phone } = user;
  const [cars, setCars] = useState([]);
  const [forms, setForms] = useState([]);
  const [error, setError] = useState(false);
  // const [users, setUsers] = useState(0);
  // const [orders, setOrders] = useState(0);
  const loadCars = () => {
    getAllCars().then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setCars(data.cars);
      }
    });
  };
  const loadForms = (token) => {
    getAllForms(token).then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setForms(data.forms);
      }
    });
  };
  // const loadUsers = () => {
  //   getUsersCount().then((data) => {
  //     if (data.error) {
  //       setError(data.error);
  //     } else {
  //       setUsers(data.userCount);
  //     }
  //   });
  // };
  // const loadOrders = () => {
  //   getOrdersCount().then((data) => {
  //     if (data.error) {
  //       setError(data.error);
  //     } else {
  //       setOrders(data.totalOrders);
  //     }
  //   });
  // };
  useEffect(() => {
    loadCars();
    loadForms(token);
  }, [token]);

  const bg = {
    order: {
      background:
        "linear-gradient(45deg, hsla(43, 100%, 85%, 1) 0%, hsla(0, 47%, 93%, 1) 100%)",
      color: "rgba(112, 112, 112, 0.58)",
    },
  };

  return (
    <>
      {error && (
        <div className="bg-red-500 text-white p-4">{error}</div>
      )}
      <br />
      <br />
      <div className="p-4">
        <div className="rounded-full shadow-lg bg-orange-400 w-32 h-32 border-1 border-red-500 mx-auto">
          <img
            // src={`${BACKEND_URL}/user/photo/${user._id}`}
            src={logoProf}
            alt="user"
            className="rounded-full h-full w-full"
          />
        </div>
        <br />
        <div className="text-center bg-gray-100 p-2 px-6 rounded-lg">
          <h1 className="text-2xl my-2 font-bold">{name}</h1>
          <h3 className="text-sm my-2 font-semibold">{email}</h3>
          <h3 className="text-sm my-2 font-semibold">{address}</h3>
          <h3 className="text-sm my-2 font-semibold">{phone}</h3>
          <h3
            className="text-md my-2 py-1 text-center"
            style={{
              backgroundColor: "#A8D26D",
              color: "white",
              borderRadius: "7px",
            }}
          >
            Admin 👨‍💼
          </h3>
        </div>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 p-4 mt-16">
        {/* <Cards
          title={"Orders"}
          counts={orders}
          desc={"Manage Orders"}
          bg={bg.order}
          linkTo={"/admin/manage/orders"}
        /> */}
        <Cards
          title={"Cars"}
          counts={cars.length}
          desc={"Manage Cars"}
          bg={bg.order}
        />
        {/* <Cards
          title={"Users"}
          counts={users}
          desc={"Manage Users"}
          bg={bg.order}
          linkTo={"/admin/manage/users"}
        /> */}
        <Cards
          title={"Forms"}
          counts={forms.length}
          desc={"Manage Forms"}
          bg={bg.order}
        />
      </div>
    </>
  );
}

export default Dashboard;
