import React from "react";
import BACKEND_URL from "../../Config";

function ShowImage({ car, url }) {
  return (
    <div className="h-44 overflow-hidden">
      <img
        src={`${BACKEND_URL}/${url}/photo/${car._id}`}
        alt={car.carModel}
        className="object-cover h-full w-full"
      />
    </div>
  );
}

export default ShowImage;
