import React, { useEffect, useState }  from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { isAuthenticated } from "../auth";
import { getAllForms, deleteForm } from "./api";
import EditModal from './EditModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CustomizedTables() {
  const { user, token } = isAuthenticated();
  console.log("token:", token);
  const [forms, setForms] = useState([]);
  const [error, setError] = useState(false);
  const loadForms = (token) => {
    getAllForms(token).then((data) => {
      if (data.error) {
        setError(data.error);
      }
      if (data.forms.length === 0) setError("No forms found!");
      setForms(data.forms);
    });
  };
  useEffect(() => {
    loadForms(token);
  }, [token]);
  
  //function to handle delete button
  const handleDelete = async (formId) => {
    await deleteForm(user._id, token, formId).then(loadForms);
  };

  return (
    <TableContainer component={Paper}>
      {error ? (
          <div className="text-red-500">Error: {error}</div>
        ) : (
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell >NIC No</StyledTableCell>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Update</StyledTableCell>
            <StyledTableCell>Delete</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {forms.map((form) => (
            <StyledTableRow key={form.name}>
              <StyledTableCell >{form.nicNo}</StyledTableCell>
              <StyledTableCell >{form.fullName}</StyledTableCell>
              <StyledTableCell ><EditModal form={form}/></StyledTableCell>
              <StyledTableCell >
                        <button
                          className="bg-red-700 px-2 rounded-md text-white text-sm"
                          onClick={() => handleDelete(form._id)}
                        >
                          Delete
                        </button>
                    </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
        )}
    </TableContainer>
  );
}