import React from "react";
import { EnvelopeIcon, PhoneIcon, MapPinIcon, TvIcon } from "@heroicons/react/20/solid";
import img from "../images/about/cover3.jpeg"
const contact = [
    {
        title: 'Email',
        detail: 'ajcarppd@gmail.com',
        icon: EnvelopeIcon
    },
    {
        title: 'Phone',
        detail: '+94773912104',
        icon: PhoneIcon
    },
    {
        title: 'Address',
        detail: 'Viyaparimoolai, Point Pedro',
        icon: MapPinIcon
    },
    {
        title: 'Social Media',
        detail: 'AJ Car Rentals',
        icon: TvIcon
    }
  ]

function Contact() {
    return (
        <div>
            <div className="bg-white">
                <div className="mx-auto max-w-7xl py-15 sm:px-6 sm:py-16 lg:px-8">
                    <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                    <svg
                        viewBox="0 0 1024 1024"
                        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                        aria-hidden="true"
                    >
                        <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                        <defs>
                        <radialGradient id="shinyRedGradient">
                            <stop stopColor="#FF0000" /> {/* Red color */}
                            <stop offset={1} stopColor="#FF4500" /> {/* A darker shade of red */}
                        </radialGradient>
                        </defs>
                    </svg>
                    <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        Keep in touch with us - AJ Car Rentals.
                        <br />
                        Call us for updates.
                        </h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla.
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                        <a
                            href="/contact"
                            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                            Contact Us
                        </a>
                        <a href="/" className="text-sm font-semibold leading-6 text-white">
                            Learn more <span aria-hidden="true">→</span>
                        </a>
                        </div>
                    </div>
                    <div className="relative mt-16 h-80 lg:mt-8">
                        <img
                        className="absolute left-0 top-0 w-[40rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
                        src={img}
                        alt="App screenshot"
                        width={600}
                        height={250}
                        />
                    </div>
                    </div>
                </div>
            </div>
            <div className="bg-white py-20 sm:py-32">
                <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
                    <div className="max-w-2xl">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Contact Us for any question</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            We're here to assist you with all your car rental needs. Feel free to reach out to us through any of the following contact methods.
                        </p>
                    </div>
                    <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                    {contact.map((item) => (
                        <li key={item.title}>
                        <div className="flex items-center gap-x-6">
                            {item.icon && React.createElement(item.icon, { className: 'h-6 w-6 text-gray-500' })}
                            <div>
                            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{item.title}</h3>
                            <p className="text-sm font-semibold leading-6 text-red-600">{item.detail}</p>
                            </div>
                        </div>
                        </li>
                    ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Contact;