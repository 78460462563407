import React from "react";

function Footer() {
  const iconStyle = {
    marginRight: "16px", // Add spacing between icons
    fontSize: "28px", // Increase the icon size
  };

  const textStyle = {
    fontFamily: "Raleway, sans-serif",
    fontSize: "14px",
    letterSpacing: "0.5px", // Add letter spacing to the text
  };

  return (
    <section className="bg-black w-full text-white py-8">
      <div className="text-center py-4" style={textStyle}>
        <p>
          AJ Car Rentals is in Point Pedro, Jaffna, but we provide a wide range
          of valuable services all over Sri Lanka to our customers with many
          offers and discounts. We are very customer-friendly.
        </p>
      </div>
      <div className="text-center mt-4">
        {/* Column 1 */}
        <div>
          <b style={{ ...textStyle, fontSize: "16px" }}>AJ Car Rentals</b>
          <ul className="mt-4" style={textStyle}>
            <li>Categories</li>
            <li>Home</li>
            <li>About</li>
            <li>Services</li>
            <li>Gallery</li>
            <li>Contact</li>
          </ul>
        </div>
        <div className="mt-4">
          <a href="https://www.facebook.com" className="text-white">
            <i className="fab fa-facebook" style={iconStyle}></i>
          </a>
          <a href="https://www.instagram.com" className="text-white">
            <i className="fab fa-instagram" style={iconStyle}></i>
          </a>
          <a href="https://www.twitter.com" className="text-white">
            <i className="fab fa-twitter" style={iconStyle}></i>
          </a>
        </div>
      </div>

      {/* Copyright Notice */}
      <div className="text-center mt-8" style={textStyle}>
        <p>&copy; AJ Car Rentals 2023</p>
        <p>Developed by Oreos2.com</p>
      </div>
    </section>
  );
}

export default Footer;
