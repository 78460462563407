import React, { useEffect } from "react";
import { useState } from "react";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import { updateCar } from "../car/carAPI";
import { isAuthenticated } from "../auth/index";

function UpdateCar({car}) {
  const {
    carMake,
    carModel,
    carColor,
    carPrice,
    carType,
    carId,
    carCapacity,
    _id,
  } = car;
  const { user, token } = isAuthenticated();
  const [values, setValues] = useState({
    carMake: carMake,
    carModel: carModel,
    carColor: carColor,
    carPrice: carPrice,
    carType: carType,
    carId: carId,
    carPhoto: "",
    carCapacity: carCapacity,
    setError: "",
    formData: new FormData(),
    loading: false,
    setSuccess: false,
    updatedCar: "",
  });
  const { setError, loading, setSuccess, updatedCar, formData } = values;
  useEffect(() => {
    formData.set("carMake", carMake);
    formData.set("carModel", carModel);
    formData.set("carColor", carColor);
    formData.set("carPrice", carPrice);
    formData.set("carId", carId);
    formData.set("carCapacity", carCapacity);
    formData.set("carType", carType);
  }, [formData, carMake, carModel, carColor, carPrice, carId, carCapacity, carType]);


  const handleChange = (name) => (event) => {
    const value =
      name === "carPhoto" ? event.target.files[0] : event.target.value;
    formData.set(name, value);
    setValues({ ...values, loading: false, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      setError: "",
      loading: true,
    });
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    updateCar(_id, user._id, token, formData).then((response) => {
      if (response.error) {
        setValues({ ...values, loading: false, setError: response.error });
      } else {
        setValues({
          carMake: "",
          carModel: "",
          carColor: "",
          carPrice: "",
          carType: "",
          carId: "",
          carCapacity: "",
          loading: false,
          setSuccess: true,
          setError: false,
          formData: new FormData(),
          updatedCar: response.carMake + "-" + response.carModel,
        });
      }
    });
  };
  const showMsg = () => {
    return (
      (setError || setSuccess) && (
        <ErrorHandler
          alertMessage={
            loading
              ? "Loading..."
              : setError
              ? setError
              : updatedCar + " was updated successfully!"
          }
          alertType={loading ? "loading" : setError ? "error" : "success"}
        />
      )
    );
  };
    return (
            <div className="flex flex-col-1 justify-start text-2xl p-6 mb-48">
                {showMsg()}
                <form className="w-full max-w-lg" onSubmit={handleSubmit}>
                    <h1 className="text-3xl text-red-500 py-8">Update Car</h1>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Car Make
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="Toyota/Honda"
                            name="carMake"
                            value={values.carMake}
                            onChange={handleChange("carMake")} 
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Car Model
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="Vezel / Alto"
                            name="carModel"
                            value={values.carModel}
                            onChange={handleChange("carModel")} 
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Car Colour
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="Color"
                            name="carColor"
                            value={values.carColor}
                            onChange={handleChange("carColor")}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Car Price / Km
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="Price/Km"
                            name="carPrice"
                            value={values.carPrice}
                            onChange={handleChange("carPrice")}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Car Type
                        </label>
                            <tr>
                                <td>
                                    <input
                                    type="radio"
                                    name="carType"
                                    value="Petrol"
                                    onChange={handleChange("carType")}
                                    />
                                    <label htmlFor="carType" />
                                    Petrol &nbsp;
                                    <input
                                    type="radio"
                                    name="carType"
                                    value="Diesel"
                                    onChange={handleChange("carType")}
                                    />
                                    <label htmlFor="carType" />
                                    Diesel &nbsp;
                                    <input
                                    type="radio"
                                    name="carType"
                                    value="EV"
                                    onChange={handleChange("carType")}
                                    />
                                    <label htmlFor="carType" />
                                    EV
                                </td>
                            </tr>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Car Capacity
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="number" 
                            placeholder="Include driver"
                            name="carCapacity"
                            min={2}
                            onChange={handleChange("carCapacity")}
                            value={values.carCapacity}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Car ID
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="001"
                            name="carId"
                            value={values.carId}
                            onChange={handleChange("carId")}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Car Image
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="file" 
                            name="carPhoto"
                            accept=".jpg,.png,.jpeg,.webp"
                            onChange={handleChange("carPhoto")}
                        />
                        </div>
                    </div>
                    <input
                        type="submit"
                        value="Submit"
                        className="rounded-md w-24 hover:shadow-md text-xl px-3 py-1 bg-zinc-700 text-amber-400"
                    />
                </form>
            </div>
    )
}

export default UpdateCar;