import React from "react";
import { useState } from "react";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import { addForm } from "./api";
import { isAuthenticated } from "../auth/index";

function AddForm() {
    const { user, token } = isAuthenticated();
    const [values, setValues] = useState({
        nicNo: "",
        carModel: "",
        vehicleRegisterNo: "",
        fullName: "",
        address: "",
        drivingLicenceNo: "",
        drivingLicencePhoto: "",
        phoneNo: "",
        hireDate: "",
        rentalRs: "",
        depositNo: "",
        startKm: "",
        finishKm: "",
        setError: "",
        formData: new FormData(),
        loading: false,
        setSuccess: false,
        addedForm: "",
    });
    const {
        nicNo,
        carModel,
        vehicleRegisterNo,
        fullName,
        address,
        drivingLicenceNo,
        phoneNo,
        hireDate,
        rentalRs,
        depositNo,
        startKm,
        finishKm,
        setError,
        setSuccess,
        addedForm,
        loading,
        formData,
    } = values;

    const handleChange = (name) => (event) => {
        const value =
        name === "drivingLicencePhoto" ? event.target.files[0] : event.target.value;
        formData.set(name, value);
        setValues({ ...values, [name]: value });
        console.log(name, value);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setValues({
        ...values,
        setError: "",
        loading: true,
        });
        addForm(user._id, token, formData).then((data) => {
        if (data.error) {
            setValues({ ...values, setError: data.error });
        } else {
            setValues({
                nicNo: "",
                carModel: "",
                vehicleRegisterNo: "",
                fullName: "",
                address: "",
                drivingLicenceNo: "",
                drivingLicencePhoto: "",
                phoneNo: "",
                hireDate: "",
                rentalRs: "",
                depositNo: "",
                startKm: "",
                finishKm: "",
                loading: false,
                setSuccess: true,
                setError: false,
                formData: new FormData(),
                addedForm: data.fullName + "-" + data.nicNo,
            });
        }
        });
    };
    const showMsg = () => {
        return (
        (setError || setSuccess) && (
            <ErrorHandler
            alertMessage={
                loading
                ? "Loading..."
                : setError
                ? setError
                : addedForm + " was added successfully!"
            }
            alertType={loading ? "loading" : setError ? "error" : "success"}
            />
        )
        );
    };
    return (
            <div className="flex flex-col-1 justify-start text-2xl p-6 mb-48">
                {showMsg()}
                <form className="w-full max-w-lg" onSubmit={handleSubmit}>
                    <h1 className="text-3xl text-red-500 py-8">Register New Clients</h1>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            NIC Number
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="xxxxxxxxxV"
                            name="nicNo"
                            value={nicNo}
                            onChange={handleChange("nicNo")} 
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Full Name
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="Client Name"
                            name="fullName"
                            value={fullName}
                            onChange={handleChange("fullName")}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Car Model
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="Vezel / Alto"
                            name="carModel"
                            value={carModel}
                            onChange={handleChange("carModel")} 
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Vehicle Register No
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="NP CAR 0044"
                            name="vehicleRegisterNo"
                            value={vehicleRegisterNo}
                            onChange={handleChange("vehicleRegisterNo")}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Address
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="Current Address"
                            name="address"
                            onChange={handleChange("address")}
                            value={address}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Driving Licence Number
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="3XCFFG"
                            name="drivingLicenceNo"
                            onChange={handleChange("drivingLicenceNo")}
                            value={drivingLicenceNo}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Phone Number
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="077xxxxxxx"
                            name="phoneNo"
                            value={phoneNo}
                            onChange={handleChange("phoneNo")}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Driving Licence Photo
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="file" 
                            name="drivingLicencePhoto"
                            accept=".jpg,.png,.jpeg,.webp"
                            onChange={handleChange("drivingLicencePhoto")}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Hire Date
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="date"
                            name="hireDate"
                            onChange={handleChange("hireDate")}
                            value={hireDate}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Rental Rs
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text"
                            name="rentalRs"
                            placeholder="In Rs per Km"
                            onChange={handleChange("rentalRs")}
                            value={rentalRs}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Deposit No
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text"
                            name="depositNo"
                            placeholder="Deposit Number"
                            onChange={handleChange("depositNo")}
                            value={depositNo}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Start Km
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text"
                            name="startKm"
                            placeholder="In Km"
                            onChange={handleChange("startKm")}
                            value={startKm}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Finish Km
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text"
                            name="finishKm"
                            placeholder="In Km"
                            onChange={handleChange("finishKm")}
                            value={finishKm}
                        />
                        </div>
                    </div>
                    <input
                        type="submit"
                        value="Submit"
                        className="rounded-md w-24 hover:shadow-md text-xl px-3 py-1 bg-zinc-700 text-amber-400"
                    />
                </form>
            </div>
    )
}

export default AddForm;