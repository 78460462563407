import React from "react";
import { Link } from "react-router-dom";

function Cards(props) {
  return (
    <Link to={props.linkTo} className="block mb-4 sm:mb-0">
      <div
        className="flex flex-col text-center items-center justify-start w-40 sm:w-auto p-4 rounded-2xl hover:shadow-lg"
        style={props.bg}
      >
        <h1 className="text-2xl sm:text-4xl">{props.title}</h1>
        <br />
        <h2 className="text-xl sm:text-2xl">{props.counts}</h2>
        {props.counts == null && props.icon}
        <br />
        <p className="text-base sm:text-xl">{props.desc}</p>
      </div>
    </Link>
  );
}

export default Cards;
