import "./App.css";
import Routess from "./Routess";

function App() {
  return (
    <>
      <Routess />
    </>
  );
}

export default App;
