import React, { useEffect } from "react";
import { useState } from "react";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import { updateForm } from "./api";
import { isAuthenticated } from "../auth/index";

function UpdateForm({form}) {
  const {
        nicNo,
        carModel,
        vehicleRegisterNo,
        fullName,
        address,
        drivingLicenceNo,
        phoneNo,
        hireDate,
        rentalRs,
        depositNo,
        startKm,
        finishKm,
        _id,
  } = form;
  const { user, token } = isAuthenticated();
  const [values, setValues] = useState({
        nicNo: nicNo,
        carModel: carModel,
        vehicleRegisterNo: vehicleRegisterNo,
        fullName: fullName,
        address: address,
        drivingLicenceNo: drivingLicenceNo,
        drivingLicencePhoto: "",
        phoneNo: phoneNo,
        hireDate: hireDate,
        rentalRs: rentalRs,
        depositNo: depositNo,
        startKm: startKm,
        finishKm: finishKm,
        setError: "",
        setSuccess: false,
        updatedForm: "",
        formData: new FormData(),
        loading: false,
  });
  const { setError, loading, setSuccess, formData } = values;
  useEffect(() => {
    formData.set("nicNo", nicNo);
    formData.set("vehicleRegisterNo", vehicleRegisterNo);
    formData.set("carModel", carModel);
    formData.set("fullName", fullName);
    formData.set("address", address);
    formData.set("drivingLicenceNo", drivingLicenceNo);
    formData.set("phoneNo", phoneNo);
    formData.set("hireDate", hireDate);
    formData.set("rentalRs", rentalRs);
    formData.set("depositNo", depositNo);
    formData.set("startKm", startKm);
    formData.set("finishKm", finishKm);
  }, [formData, vehicleRegisterNo, carModel, fullName, address, drivingLicenceNo, phoneNo, hireDate, rentalRs, depositNo, startKm, finishKm, nicNo]);


  const handleChange = (name) => (event) => {
    const value =
      name === "drivingLicencePhoto" ? event.target.files[0] : event.target.value;
    formData.set(name, value);
    setValues({ ...values, loading: false, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      setError: "",
      loading: true,
    });
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    updateForm(_id, user._id, token, formData).then((response) => {
      if (response.error) {
        setValues({ ...values, loading: false, setError: response.error });
      } else {
        setValues({
          nicNo: "",
          carModel: "",
          vehicleRegisterNo: "",
          fullName: "",
          address: "",
          drivingLicenceNo: "",
          drivingLicencePhoto: "",
          phoneNo: "",
          hireDate: "",
          rentalRs: "",
          depositNo: "",
          startKm: "",
          finishKm: "",
          loading: false,
          setSuccess: true,
          setError: false,
          formData: new FormData(),
          updatedForm: response.nicNo + "-" + response.fullName,
        });
      }
    });
  };
  const showMsg = () => {
    return (
      (setError || setSuccess) && (
        <ErrorHandler
          alertMessage={
            loading
              ? "Loading..."
              : setError
              ? setError
              : updateForm + " was updated successfully!"
          }
          alertType={loading ? "loading" : setError ? "error" : "success"}
        />
      )
    );
  };
    return (
            <div className="flex flex-col-1 justify-start text-2xl p-6 mb-48">
                {showMsg()}
                <form className="w-full max-w-lg" onSubmit={handleSubmit}>
                    <h1 className="text-3xl text-red-500 py-8">Register New Clients</h1>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            NIC Number
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="xxxxxxxxxV"
                            name="nicNo"
                            value={values.nicNo}
                            onChange={handleChange("nicNo")} 
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Full Name
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="Client Name"
                            name="fullName"
                            value={values.fullName}
                            onChange={handleChange("fullName")}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Car Model
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="Vezel / Alto"
                            name="carModel"
                            value={values.carModel}
                            onChange={handleChange("carModel")} 
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Vehicle Register No
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="NP CAR 0044"
                            name="vehicleRegisterNo"
                            value={values.vehicleRegisterNo}
                            onChange={handleChange("vehicleRegisterNo")}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Address
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="Current Address"
                            name="address"
                            onChange={handleChange("address")}
                            value={values.address}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Driving Licence Number
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="3XCFFG"
                            name="drivingLicenceNo"
                            onChange={handleChange("drivingLicenceNo")}
                            value={values.drivingLicenceNo}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Phone Number
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text" 
                            placeholder="077xxxxxxx"
                            name="phoneNo"
                            value={values.phoneNo}
                            onChange={handleChange("phoneNo")}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Driving Licence Photo
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="file" 
                            name="drivingLicencePhoto"
                            accept=".jpg,.png,.jpeg,.webp"
                            onChange={handleChange("drivingLicencePhoto")}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Hire Date
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="date"
                            name="hireDate"
                            onChange={handleChange("hireDate")}
                            value={values.hireDate}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Rental Rs
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text"
                            name="rentalRs"
                            placeholder="In Rs per Km"
                            onChange={handleChange("rentalRs")}
                            value={values.rentalRs}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Deposit No
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text"
                            name="depositNo"
                            placeholder="Deposit Number"
                            onChange={handleChange("depositNo")}
                            value={values.depositNo}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Start Km
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text"
                            name="startKm"
                            placeholder="In Km"
                            onChange={handleChange("startKm")}
                            value={values.startKm}
                        />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Finish Km
                        </label>
                        <input 
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-last-name" 
                            type="text"
                            name="finishKm"
                            placeholder="In Km"
                            onChange={handleChange("finishKm")}
                            value={values.finishKm}
                        />
                        </div>
                    </div>
                    <input
                        type="submit"
                        value="Submit"
                        className="rounded-md w-24 hover:shadow-md text-xl px-3 py-1 bg-zinc-700 text-amber-400"
                    />
                </form>
            </div>
    )
}

export default UpdateForm;