import React,{ useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../auth";
import { getAllCars, deleteCar } from "../car/carAPI";

import EditModal from "./EditModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CustomizedTables() {
    const { user, token } = isAuthenticated();
  const [cars, setCars] = useState([]);
  const [error, setError] = useState(false);
  const loadCars = () => {
    getAllCars().then((data) => {
      
      if (data.error) {
        setError(data.error);
      }
      if (data.cars.length === 0) setError("No cars found!");
      setCars(data.cars);
    });
  };
  useEffect(loadCars, []);

  //function to handle delete button
  const handleDelete = async (carId) => {
    await deleteCar(user._id, token, carId).then(loadCars);
  };

  return (
    <TableContainer component={Paper}>
        {error ? (
          <div className="text-red-500">Error: {error}</div>
        ) : (
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell >Car Make</StyledTableCell>
            <StyledTableCell >Car Model</StyledTableCell>
            <StyledTableCell >Update</StyledTableCell>
            <StyledTableCell >Delete</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {cars.length > 0 &&
                cars.map((car, index) => {
                    return(
            <StyledTableRow key={car._id}>
              <StyledTableCell >{car.carMake}</StyledTableCell>
              <StyledTableCell >{car.carModel}</StyledTableCell>
              <StyledTableCell ><EditModal car={car}/></StyledTableCell>
              <StyledTableCell >
                        <button
                          className="bg-red-700 px-2 rounded-md text-white text-sm"
                          onClick={() => handleDelete(car._id)}
                        >
                          Delete
                        </button>
                    </StyledTableCell>
            </StyledTableRow>
                    );
        })}
        </TableBody>
      </Table>
        )}
    </TableContainer>
  );
}