import { isAuthenticated } from "../auth";
import BACKEND_URL from "../../Config";
//let's create a method to get cars

export const getAllForms = (token) => {
  return fetch(`${BACKEND_URL}/forms`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => err);
};

//read function
export const read = (formID) => {
  return fetch(`${BACKEND_URL}/form/${formID}`, {
    method: "GET",
  })
    .then((data) => {
      return data.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

// Create a method to post a new form
export const addForm = (userId, token, form) => {
  return fetch(`${BACKEND_URL}/form/add/${userId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: form,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

// Create a method to update a form
export const updateForm = async (formId, userId, token, form) => {
  return fetch(`${BACKEND_URL}/form/update/${formId}/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: form,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

// Create a method to delete a form by id
export const deleteForm = async (userId, token, formId) => {
  if (isAuthenticated()) {
    return fetch(`${BACKEND_URL}/form/delete/${formId}/${userId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  }
};
