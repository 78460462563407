import React, {useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../auth/index";
import { Bars3Icon, XMarkIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
import logo from "../../images/logo.png";

const navigation = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Services', href: '/services' },
    { name: 'Contact-us', href: '/contact' },
  ]

function Navbar() {
    const { user, clearUserData } = isAuthenticated(); // Assume clearUserData is a function to clear user data
    const navigate = useNavigate();
    const handleSignout = () => {
        navigate("/signin");
        signout(() => {
        clearUserData(); // Clear user data when signing out
        navigate("/signin");
        });
  };
    const location = useLocation();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    return (
        <div className="bg-white position">
        <header className="inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div className="flex lg:flex-1">
                <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">AJ Car Rentals</span>
                <img
                    className="h-8 w-auto"
                    src={logo}
                    alt=""
                />
                </a>
            </div>
            <div className="flex lg:hidden">
                <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
                >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
                {navigation.map((item) => (
                <a
                key={item.name}
                href={item.href}
                className={`text-sm font-semibold leading-6 ${
                    location.pathname === item.href
                        ? "text-red-600" // Apply active style when URL matches href
                        : "text-gray-900"
                }`}
            >
                    {item.name}
                </a>
                ))}
                {isAuthenticated() && (
                <a
                href={user && user.role === 1 ? "/admin/dashboard" : "/user/dashboard"}
                className={`text-sm font-semibold leading-6 ${
                    location.pathname === "/admin/dashboard"
                        ? "text-red-600" // Apply active style when URL matches href
                        : "text-gray-900"
                }`}
                >
                    Dashboard
                </a>
                )}
            </div>
            {isAuthenticated() ? (
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                <button 
                    onClick={handleSignout}
                    className="text-sm font-semibold leading-6 text-white bg-red-500 hover:bg-red-600 py-1 px-4 rounded-lg flex items-center space-x-1">
                    Log out
                    <LockClosedIcon className="h-4 w-4" aria-hidden="true" />
                </button>
            </div>
            ) : (
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                <a href="/signin" className="text-sm font-semibold leading-6 text-gray-900">
                    Log in <span aria-hidden="true">&rarr;</span>
                </a>
            </div>
            )}
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                <a href="/" className="-m-1.5 p-1.5">
                    <span className="sr-only">AJ Car Rentals</span>
                    <img
                    className="h-8 w-auto"
                    src={logo}
                    alt=""
                    />
                </a>
                <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                </div>
                <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                        <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                        {item.name}
                        </a>
                    ))}
                    {isAuthenticated() && (
                        <a
                        href={user.role === 1 ? "/admin/dashboard" : "/user/dashboard"}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                            Dashboard
                        </a>
                    )}
                    </div>
                    {isAuthenticated() ? (
                    <div className="py-6">
                    <button 
                        onClick={handleSignout}
                        className="text-sm font-semibold leading-6 text-white bg-red-500 hover:bg-red-600 py-1 px-4 rounded-lg flex items-center space-x-1">
                        Log out
                        <LockClosedIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                    </div>
                    ) : (
                    <div className="py-6">
                    <a
                        href="/signin"
                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                        Log in
                    </a>
                    </div>
                    )}
                </div>
                </div>
            </Dialog.Panel>
            </Dialog>
        </header>
      </div>

    )
}

export default Navbar;