import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import UpdateCar from './UpdateCar';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
    maxHeight: '80vh', // Set a maximum height for scrolling
    overflowY: 'auto', // Enable vertical scrolling when content overflows
  };

export default function EditModal({car}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getModalWidth = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      // For screens narrower than 768px (e.g., mobile devices), make it wider
      return '90%'; // You can adjust the percentage as needed
    }
    // For larger screens, keep the default width
    return 'auto';
  };

  return (
    <div>
      <Button onClick={handleOpen} style={{backgroundColor:"blue", color:"white"}}>Update</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: getModalWidth() }}>
          <UpdateCar car={car}/>
        </Box>
      </Modal>
    </div>
  );
}